* {
    box-sizing: border-box;
}

#root {
    background-color: #7618AA;
}
header {
    height: 85px;
}

.navbar {
    box-shadow: 0 9px 18px -6px rgba(0,0,0,0.75);
    padding: 0.7rem 1rem ;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 900;
    letter-spacing: .3rem;


}

.navbar-brand{
    background-image: url("./img/Flashcards.jpg");
    width: 55px;
    height: 55px;
    position: relative;
    margin-left: 3rem;

}
.navbar-brand::after {
    content: "";
    display: block;
    background-color: orange;
    width: 20px;
    height: 55px;
    position: absolute;
    left: 50px;
    bottom: 0.7px;
    z-index: -1;

}

.navbar-nav{
    margin-right: 1rem;
}


.nav-item{
    color: purple;
    padding: 1rem;

}
.nav-item:hover {
    background-color: orange;
    color: white;
}

footer div{
    color: #7618AA;
    background-color: #f8f9fa;
    padding: .8rem 1rem;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 900;
    letter-spacing: .3rem;
    text-align: center;

}
@media screen and (min-width: 765px) and (max-width: 960px){
    .nav-item {
        padding: .8rem 0.5rem;
        letter-spacing: .1rem;
    }
}

@media screen and (max-width: 765px){
    .navbar-brand {
        margin-left: 1.3rem;
    }
    .navbar-toggler {
        margin-right: 1rem;
    }
}
.bgOrange {
    background-color: orange;
    color: white;
    border: none;
}