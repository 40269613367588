.img {
    height: 78.5vh;
    font-size: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

}

@media screen and (max-width: 600px){
    .img {
        font-size: 7rem;
    }
    h3 {
        font-size: 1.5rem;
        margin-bottom: 3rem;
    }
    .withoutMargin {
        margin-bottom: 0;
    }
}
@media screen and (max-width: 320px){
    h3 {
        font-size: 1.3rem;
    }
    .withoutMargin {
        font-size: 1rem;
    }
}

.img1 {
    background-color: #7618AA;
}
.img2 {
    background-color: orange;
}